.blink{
  animation: blink 5s infinite;
}
@keyframes blink{
  0% {
    color: white;
  }
  20% {
    color: #FF13A7;
  }
  40% {
    color: white;
  }
  60% {
    color: #FF13A7;
  }
  80% {
    color: white;
  }
  100% {
    color: #FF13A7;
  }
}

@-webkit-keyframes blink{
  0% {
    color: white;
  }
  20% {
    color: #FF13A7;
  }
  40% {
    color: white;
  }
  60% {
    color: #FF13A7;
  }
  80% {
    color: white;
  }
  100% {
    color: #FF13A7;
  }
}

a.custom{

  color:#FF13A7;
}
a.custom:hover{
  animation: blink 5s infinite linear;
}

/*default-btn*/
.default-btn-custom {
  border: none;
  position: relative;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  color: white;
  background-color: #8B008B;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 5px;
  padding: 12px 25px 12px 25px;
  font-weight: 700;
  font-size: 16px;
}

.progress-section-large {
  display: flex;
  width: 16%;
  flex-direction: column;
}

.progress-section-small {
  display: flex;
  width: 13%;
  flex-direction: column;
}

.progress-bar-section {
  display: flex;
  flex-direction: row;
}
.progress-bar {
  width: 0%;
  height: 7px;
  background-color: #00ffe5;
}

.progress-bar-pending {
  flex-grow: 1;
  height: 7px;
  background-color: orange;
}

#progress {
  display: flex;
  flex-direction: row;
  padding-left: 9%;
  width: 95%;
  margin-top: 1em;
  color: white;
}

.progress-tick {
  width: 2px;
  height: 21px;
  background-color: #c4c4c4;
  margin-top: -14px;
  align-self: flex-end;
}

.progress-tick-end {
  width: 2px;
  height: 21px;
  background-color: rgba(0, 0, 0, 0);
  margin-top: -14px;
  align-self: flex-end;
}

.stage-name {
  text-align: center;
  line-height: 25px;
  font-size: 0.8em;
}

.stage-price {
  text-align: center;
  line-height: 25px;
  font-size: 0.6em;
}

.img1 {
    width: auto;
    height: 350px;
    margin: 0.10rem;
}
.img2 {
  width: auto;
  height: 290px;
  margin: 0.10rem;
}

.header-img {
 width: auto;
 height: 80px;
 -webkit-transition: 1s;
 transition: 1s;
}

.header-img:hover {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);

}

.banner-img {
  -webkit-transition: 1s;
  transition: 1s;
 }
 
 .banner-img:hover {
   -webkit-transform: translateY(-5px);
           transform: translateY(-5px);
 
 }

.banner-wrapper-content  .btn-box .default-btn-custom {
  top: -.3px;
  margin-left: 15px;
  background-color: transparent;
  z-index: 1;
  height: 47px;
  color: #ffffff;
}

.banner-wrapper-content .btn-box .default-btn-custom::before {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  border-radius: 5px;
  bottom: 0;
  left: 0;
  border: 1px solid #ffffff;
}

.banner-wrapper-content .btn-box .default-btn-custom:hover {
  color:  #ffffff;
}

.bx-custom {
  font-size: 20px !important;
}

.custom-followus {
  color: #ffffff;
  margin-left: 10px;

}

.circle-custom { 
  background: #DBD2D0; 

  border-radius: 10px;
}

.circle-custom-artwork { 
  border-radius: 12px;
}

.bx-instagram-custom {
  color: #FF13A7;
  font-size: 98px;
}

.bx-large-custom {
  color: #FF13A7;
  font-size: 98px;
}

.bg-282d41 {
  background-color: #282d41;
}

.bg-FF13A7 {
  background-color: #FF13A7;
}

.color-FF13A7 {
  color: #FF13A7;
}

.color-FFFFFF {
  color: #FFFFFF;
}

.ptb-custom-100 {
  padding-top: 25px;
  padding-bottom: 100px;
}

.footer-custom {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;

}

.border-line-bottom {
  border-bottom: 2px solid #c5c5c5;
}

.back-ground-text {
  background-color: #8B008B;
  color:white;
}

.back-ground-gray {
  background-color: #282d41;
}

.pt-50 {
  padding-top: 50px;
}

.pt-20 {
  padding-top: 20px;
}

.w-35 {
  width: 35% !important;
}
.orange-border{
  padding-bottom: 20px;
  border-bottom: 2px solid #8B008B;
}

.orange-top{
  padding-top: 20px;
  border-top: 2px solid #8B008B;
}

.tag-style{
    background: #FF13A7;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.about-image-box-custom {
  text-align: center;
  position: relative;
  margin-right: 20px;
  border-radius: 5px;
  padding-bottom: 10px;
  padding-right: 15px;
}

.about-image-box-custom::before {
  content: '';
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 5px;
  position: absolute;
  background: #8B008B;
  z-index: -1;
  margin-top: 10px;
  margin-left: 35px;
  margin-right: 35px;
}

.size-details-info {
  width: 450px;
  height: 290px;
}

.footer-bottom-area-min {
  margin-top: 0px;
  border-top: 1px solid #f3f2ee;
  padding-top: 1px;
  padding-bottom: 1px;
}

.footer-bottom-area-min p i {
  position: relative;
  top: 1.3px;
}

.footer-bottom-area-min p a {
  color: #8B008B;
  font-weight: 600;
}

.footer-bottom-area-min p a:hover {
  color: #221638;
}

.footer-bottom-area-min p strong {
  color: #221638;
}

.footer-bottom-area-min ul {
  text-align: right;
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.footer-bottom-area-min ul li {
  color: #6b6b84;
  position: relative;
  display: inline-block;
  margin-right: 20px;
}

.footer-bottom-area-min ul li:last-child {
  margin-right: 0;
}

.footer-bottom-area-min ul li:last-child::before {
  display: none;
}

.footer-bottom-area-min ul li::before {
  content: '';
  position: absolute;
  right: -12px;
  top: 5.6px;
  width: 1px;
  height: 14px;
  background-color: #6b6b84;
}

.footer-bottom-area-min ul li a {
  display: block;
  color: #6b6b84;
}

.footer-bottom-area-min ul li a:hover {
  color: #8B008B;
}

.tarn-nav .navbar .navbar-brand img {
  max-width: 300px;
}


.footer-bottom-area-custom {
  margin-top: 10px;
  padding-top: 1px;
  padding-bottom: 1px;
  border-top: 1px solid #f3f2ee;
}

.footer-bottom-area-custom ul {
  text-align: right;
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.footer-bottom-area-custom ul li {
  color: #6b6b84;
  position: relative;
  display: inline-block;
  margin-right: 20px;
}

.footer-bottom-area-custom ul li:last-child {
  margin-right: 0;
}

.footer-bottom-area-custom ul li:last-child::before {
  display: none;
}

.footer-bottom-area-custom ul li::before {
  content: '';
  position: absolute;
  right: -12px;
  top: 5.6px;
  width: 1px;
  height: 14px;
  background-color: #6b6b84;
}

.footer-bottom-area-custom ul li a {
  display: block;
  color: #6b6b84;
}

.footer-bottom-area-custom ul li a:hover {
  color: #8B008B;
}

.projects-area div .cella-font {
  font-family: cella;
  color: #ffffff;
  font-size: 20px;
}

.red-underline {
  background-color: #FF13A7;
  --lnw: 5px;
  --brd: var(--color_18);
  --alpha-brd: 1;
  transform-origin: center 2.5px;
  width: 43px;
  height: 5px;

}

.font-Nunito {
  font-family: 'Nunito';
}



.trapizium {
  padding: 5px;
  font-size: 18px;
  line-height: 1;
  width: 100%;
  text-align: center;
  position: relative;
  background: #282d41;
}

.trapizium:before {
  content: "";
  position: absolute;
  border: none;
  top: -0px;
  bottom: -50%;
  left: 0px;
  right: 0px;
  z-index: -1;
  background: #282d41;
  transform: perspective(20em) rotateX(-45deg);
  transform-origin: top;
}
/*================================================
How to buy Area CSS
=================================================*/
.how-to-buy-image-item img {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.how-to-buy-image-item:hover img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}


/*================================================
SVG 
=================================================*/
.svgcustom {
  fill:#FF13A7;

}
.svgcustom:hover,
.svgcustom:focus {
  fill: #8B008B;
}

.svgcustom-title {
  width: 165px;
}

.solutions-area .section-title {
  max-width: 800px;
}





@keyframes animloader {
  0% {
    top: 0;
    color: #FF13A7;
  }
  50% {
    top: 30px;
    color: rgba(255, 255, 255, 0.2);
  }
  100% {
    top: 0;
    color:#FF13A7;
  }
}
@media (max-width: 768px) {
  .btn-responsive {
    margin-bottom: 25px; 
    margin-left: 0px;
    padding-left: 40px;
    height: 40px;
    background-color: white;
    color:#8B008B ;
  }

  .bx-custom {
    left: 23px !important;
  }

}

@media (min-width: 769px) {
  .btn-responsive {
    margin-bottom: 25px; 
    margin-left: 0px;
    padding-left: 40px;
    height: 40px;
    background-color: white ;
    color: #8B008B ;
  }

  .bx-custom {
    left: 20px;
  }

}